import React, { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children, searchBlog }) => {
  const [searchText, setSearchText] = useState('');

  // Ensure searchBlog is always a function, even if not provided
  const safeSearchBlog =
    searchBlog || (() => console.warn('searchBlog not provided'));

  return (
    <SearchContext.Provider
      value={{ searchText, setSearchText, searchBlog: safeSearchBlog }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
