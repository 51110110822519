import { cn } from '@/utils';
import React, { useEffect, useState } from 'react';
import { useMobileResponsive } from '@/hooks';
import { useRouter } from 'next/router';
import qrImage from '../../assets/new-assets/home/hero/qrImageOrg.png';

const DownloadFooter = () => {
  const router = useRouter();
  const [isOutOfBounds, setisOutOfBounds] = useState(false);
  const [showQr, setShowQr] = useState(true);
  const { isMobile } = useMobileResponsive();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 450 || window.scrollY > 5800) {
        setisOutOfBounds(true);
        setShowQr(true);
      } else {
        setisOutOfBounds(false);
        setShowQr(false);
      }

      // showComponent;
    };
    // Add Scroll Event listener
    window.addEventListener(`scroll`, handleScroll);

    return () => window.removeEventListener(`scroll`, handleScroll);
  }, [isMobile, isOutOfBounds]);

  //Define the paths where the DownloadFooter should be hidden
  const hiddenPaths: string[] = [`/course`, `/course/success`];

  //Check if the current path is in the hiddenPaths array
  const shouldHideFooter = hiddenPaths.includes(router.pathname);

  if (shouldHideFooter) {
    return null;
  }

  return (
    <>
      {!showQr && (
        <div
          className={cn(
            `fixed bottom-4 right-4 w-32 p-4 border border-[#CDCDCD] flex justify-center bg-gradient-to-br from-[#2A105F] to-[#0F0622]  rounded-3xl`,
            {
              hidden: isOutOfBounds || isMobile,
            },
          )}
          style={{
            zIndex: `50`,
          }}
        >
          <>
            <div className="flex items-center justify-center  ">
              <img alt="Qr code" src={qrImage.src} className="w-24" />
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default DownloadFooter;

/*
     <div
        className={cn(
          `h-10  fixed bottom-0 w-full flex justify-center bg-level-purple `,
          {
            'h-0': isOutOfBounds,
          },
          {
            'h-72': isOpen,
          },
          {
            'h-80': isOpen && isMobile,
          },
        )}
        style={{
          // backgroundImage: `url(${downloadBG.src})`,
          backgroundSize: `cover`, // Cover the entire div
          backgroundPosition: `center`, // Center the background image
          backgroundRepeat: `no-repeat`, // Prevent repeating the image
          zIndex: `100`,
        }}
      >
        {isOpen ? (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={cn(`absolute top-6 right-6 text-white`)}
          >
            <FontAwesomeIcon size="lg" className="ml-3" icon={faXmarkCircle} />
          </button>
        ) : (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={cn(`absolute top-2 text-white`)}
          >
            Download the App
            <FontAwesomeIcon size="sm" className="ml-3" icon={faArrowRight} />
          </button>
        )}

        <div
          className={cn(
            `absolute  top-10 text-center block `,
            {
              'flex h-12 space-x-3': !isMobile,
            },
            {
              'space-y-2': isMobile,
            },
          )}
        >
          <button>
            <a
              className="flex w-60  p-3 justify-evenly  items-center text-white rounded-3xl bg-[#2E1169] border-2 border-[#634C93]"
              href="https://lsm.lvl.fit?data=mp61447mj0fwyhi8xs6pfc"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faAndroid} className="text-xl" />
              <h3
                className={cn(``, {
                  'text-sm': isMobile,
                })}
              >
                Download on Google Play
              </h3>
            </a>
          </button>

          <button>
            <a
              className="w-60 flex p-3 justify-evenly items-center text-white rounded-3xl bg-[#2E1169] border-2 border-[#634C93]"
              // href="https://apple.co/3Ufxniw"
              href="https://lsm.lvl.fit?data=mp61447mj0fwyhi8xs6pfc"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faApple} className="text-xl" />
              <h3
                className={cn(``, {
                  'text-sm': isMobile,
                })}
              >
                Download on App Store
              </h3>
            </a>
          </button>
        </div>
        <div
          className={cn(`absolute top-28  text-center text-white`, {
            'top-40 pt-3': isMobile,
          })}
        >
          <h2
            className={cn(`font-extrabold text-2xl `, {
              'text-sm': isMobile,
            })}
          >
            Join the Community
          </h2>
          <h4
            className={cn(``, {
              'text-xs': isMobile,
            })}
          >
            For exclusive updates on new content, mind
            <br />
            bending blogs and a lot more
          </h4>
        </div>
        <div
          className={cn(
            `absolute top-56 bg-white text-black w-64 md:w-96 rounded-full p-2`,
            {
              'top-64': isMobile,
            },
          )}
        >
          <input
            placeholder="Enter Email id"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            className="focus:outline-none font-sm"
          />
          {Email != `` && (
            <button
              onClick={() => saveEmailHandler(Email)}
              className="absolute right-2 text-white bg-purple-500  rounded-full w-10 h-6"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
        </div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
*/
