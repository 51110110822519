import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = ``;

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function invertColor(color: string) {
  return (
    `000000` + (0xffffff ^ parseInt(color.substring(1), 16)).toString(16)
  ).slice(-6);
}

//Blogs
export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? `0` + seconds : seconds}`;
};

export const shareMessage = [
  {
    id: 1,
    message: `Hey, You've got exclusive access to this blog, {Name} just because I shared it with you, Trust me it's worth it!`,
  },
  {
    id: 2,
    message: `Hey, I found this crazy blog on {Name}, check it for valuable insights!`,
  },
  {
    id: 3,
    message: `Hey, Check out this awesome blog on {Name}`,
  },
];

export const randomIndex = Math.floor(Math.random() * shareMessage.length);
