// export const FB_PIXEL_ID = `1277196556103339`;
// export const FB_PIXEL_ID = `500207002676577`;

export const pageview = () => {
  (window as any).fbq(`track`, `PageView`);
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: any, options = {}) => {
  (window as any).fbq(`track`, name, options);
};
