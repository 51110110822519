import React from 'react';
import { useCookie } from '@/hooks';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons';

export function CookiePrompt() {
  const router = useRouter();
  const cookies = useCookie();
  const [hidePrompt, setHidePrompt] = React.useState(false);

  // disable cookie for iOS. comment these lines when the app is approved
  return <></>;

  if (
    router.pathname.startsWith(`/user`) ||
    router.pathname.startsWith(`/wrapped`)
  ) {
    return <></>;
  }

  if (cookies === undefined || cookies.hasConsent || hidePrompt) {
    return <></>;
  }

  return (
    <div className="fixed bottom-[1rem] left-[1rem] right-[1rem] z-30 flex justify-end">
      <div
        style={{
          boxShadow: `3.9px 3.4px 10px rgba(0, 0, 0, 0.075), 31px 27px 80px rgba(0, 0, 0, 0.15)`,
        }}
        className={[
          `flex`,
          `gap-x-4`,
          `gap-y-8`,
          `flex-col`,
          `items-center`,
          `justify-between`,
          `max-w-3xl`,
          `bg-white`,
          `px-10`,
          `py-6`,
          `rounded-3xl`,
          `shadow-2xl`,
          `drop-shadow-2xl`,

          `md:flex-row`,
        ].join(` `)}
      >
        <div className="p-4 bg-[#ded4cd] w-[4rem] h-[4rem] flex justify-center items-center rounded-full">
          <FontAwesomeIcon icon={faCookieBite} fontSize="3em" />
        </div>

        <div className="text-xl text-center md:text-left">
          We use third-party cookies in order to personalize your site
          experience.
        </div>

        <div className="flex gap-x-4">
          <button
            className={[
              `px-6`,
              `py-3`,
              `rounded-l-3xl`,
              `rounded-r-md`,
              `border`,
              `text-md`,
              `hover:bg-gray-100`,
              `transition-colors`,
            ].join(` `)}
            onClick={() => cookies.setConsent(`YES`)}
          >
            Okay
          </button>
          <button
            className={[
              `px-6`,
              `py-3`,
              `rounded-r-3xl`,
              `rounded-l-md`,
              `border`,
              `text-md`,
              `hover:bg-gray-100`,
              `transition-colors`,
            ].join(` `)}
            onClick={() => {
              //cookies.removeConsent();
              cookies.setConsent(`YES`);
              setHidePrompt(true);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
