import React, { useEffect, useState } from 'react';
import * as cheerio from 'cheerio';

type WindowDimensionType = {
  innerWidth: number;
  outerWidth: number;
  innerHeight: number;
  outerHeight: number;
};

export function useWindowDimension() {
  const [dim, setDim] = React.useState<WindowDimensionType>();

  React.useEffect(() => {
    const windowResizeListener = () => {
      const { innerHeight, innerWidth, outerHeight, outerWidth } = window;
      setDim(() => ({ innerHeight, innerWidth, outerHeight, outerWidth }));
    };

    windowResizeListener();
    window.addEventListener(`resize`, windowResizeListener);

    return () => {
      window.removeEventListener(`resize`, windowResizeListener);
    };
  }, []);

  return dim;
}

function parseCookie(doc: Document) {
  const initValue: { [key: string]: string } = {};

  if (!doc.cookie?.trim()) return {};

  return doc.cookie
    .split(`;`)
    .map((chunk) => chunk.split(`=`))
    .reduce((acc, curr) => {
      acc[decodeURIComponent(curr[0].trim())] = decodeURIComponent(
        curr[1].trim(),
      );
      return acc;
    }, initValue);
}

export function useCookie() {
  const [cookies, setCookies] = React.useState<{ [key: string]: string }>();

  React.useEffect(() => {
    setCookies(parseCookie(document));
  }, []);

  return {
    ...cookies,

    hasConsent: cookies?.consent === `YES`,

    setConsent(consent: 'YES' | 'NO') {
      const cookieObj = parseCookie(document);
      const cookieExpiresAt = new Date();

      cookieExpiresAt.setFullYear(cookieExpiresAt.getFullYear() + 100);

      Object.defineProperty(cookieObj, `consent`, {
        enumerable: true,
        value: consent,
      });

      for (const cookieName of Object.keys(cookieObj)) {
        document.cookie = `${cookieName}=${
          cookieObj[cookieName]
        };expires=${cookieExpiresAt.toUTCString()}; path=/`;
      }

      setCookies(parseCookie(document));
    },

    removeConsent() {
      const cookieExpiresAt = new Date();

      cookieExpiresAt.setFullYear(cookieExpiresAt.getFullYear() - 100);
      document.cookie = `consent=;expires=${cookieExpiresAt.toUTCString()}; path=/`;
      setCookies(parseCookie(document));
    },
  };
}

export function useControlFloatingButton() {
  const [isHidden, setIsHidden] = React.useState(true);

  React.useEffect(() => {
    const timeOut = 2000;
    let timeoutListener = setTimeout(() => setIsHidden(() => true), timeOut);

    const eventListener = () => {
      clearTimeout(timeoutListener);
      timeoutListener = setTimeout(() => setIsHidden(() => true), timeOut);

      const scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;

      const _isHidden =
        window.document.body.scrollHeight - (window.innerHeight + 500) <
        scrollTop
          ? true
          : scrollTop > window.innerHeight
          ? false
          : true;

      setIsHidden(() => _isHidden);
    };

    window.addEventListener(`scroll`, eventListener);

    return () => {
      clearTimeout(timeoutListener);
      window.removeEventListener(`scroll`, eventListener);
    };
  }, []);

  return { isHidden };
}

export function useMobileResponsive() {
  const [isMobile, setIsMobile] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(0);

  React.useEffect(() => {
    setWindowWidth(() => window.innerWidth);

    if (windowWidth <= 768) {
      setIsMobile(() => true);
    }
    if (windowWidth >= 768) {
      setIsMobile(() => false);
    }

    window.addEventListener(`resize`, () =>
      setWindowWidth(() => window.innerWidth),
    );
  }, [windowWidth, isMobile]);

  return { isMobile, windowWidth };
}

export const useScroll = (boundStart: number, boundEnd: number) => {
  const [insideBounds, setInsideBounds] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setWindowHeight(window.scrollY);

      if (window.scrollY > boundStart && window.scrollY < boundEnd) {
        setInsideBounds(true);
      } else {
        setInsideBounds(false);
      }
    };

    // Add Scroll Event listener
    window.addEventListener(`scroll`, handleScroll);
    return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  return { insideBounds, windowHeight };
};

type Nullable<T> = T | null;

const buttonTexts = [
  `Try for Free `,
  `Download for Free `,
  `Unlock 7-day Free Premium`,
  `Get now for Free`,
  `Experience Now`,
  `Unlock Full Access Now `,
  `Unlock Premium for Free`,
];

export const useCheerioModification = (
  htmlString:
    | cheerio.AnyNode
    | cheerio.AnyNode[]
    | Buffer
    | Nullable<string>
    | undefined,
  link: string,
  selectedLanguage: string | string[],
  referrer: string,
) => {
  const [modifiedHtml, setModifiedHtml] = useState(``);

  useEffect(() => {
    if (typeof htmlString === `string`) {
      const $ = cheerio.load(htmlString);

      const baseURL = `https://mind.level.game/share/`;

      // Add 'nofollow' to appropriate links
      $(`a`).each((_, el) => {
        const href = $(el).attr(`href`);
        // console.log(`Checking link:`, href);
        if (href && href.includes(baseURL)) {
          $(el).attr(`rel`, `nofollow`);
          // console.log(`Added nofollow to:`, href);
        }
      });

      // If the referrer is not 'levelapp', add buttons
      if (referrer !== `levelapp`) {
        let buttonCount = 0;
        $(`h2`).each((_, ele) => {
          const buttonText = buttonTexts[buttonCount % buttonTexts.length];
          const button = `
            <a href="${link}" style="color: inherit; text-decoration: none;">
              <button
                style="
                  background: linear-gradient(to right, #44199A, #9D77EE);
                  color: white;
                  font-size: 0.98rem;
                  font-weight: 800;
                  padding: 0.75rem 1.25rem;
                  margin: 0.75rem 0;
                  border-radius: 1.5rem;
                  height: 3.5rem;
                  transition: all 0.5s;
                  display: inline-block;
                  transform-origin: center;
                "
                onmouseover="this.style.background='linear-gradient(to right, #9D77EE, #9D77EE, #44199A)'; this.style.transform='scale(1.05)';"
                onmouseout="this.style.background='linear-gradient(to right, #44199A, #9D77EE, #9D77EE)'; this.style.transform='scale(1)';"
              >
                ${buttonText}
              </button>
            </a>
          `;
          $(ele).before(button);
          buttonCount++;
        });
      }

      // Always use the modified HTML, which includes 'nofollow' attributes
      const updatedHtmlString = $.html();
      // console.log(`Final HTML:`, updatedHtmlString);

      setModifiedHtml(updatedHtmlString);
    }
  }, [htmlString, link, selectedLanguage, referrer]);

  return { modifiedHtml };
};

// export const useCheerioModification = (
//   htmlString:
//     | cheerio.AnyNode
//     | cheerio.AnyNode[]
//     | Buffer
//     | Nullable<string>
//     | undefined,
//   link: string,
//   selectedLanguage: string | string[],
//   referrer: string,
// ) => {
//   const [modifiedHtml, setModifiedHtml] = useState(``);

//   useEffect(() => {
//     if (typeof htmlString === `string`) {
//       const $ = cheerio.load(htmlString);

//       const baseURL = 'https://mind.level.game';

//       // Add 'nofollow' to appropriate links
//       $('a').each((i, el) => {
//         const href = $(el).attr('href');
//         if (href && href.includes(baseURL)) {
//           $(el).attr('rel', 'nofollow');
//         }
//       });

//       let buttonCount = 0;

//       $(`h2`).each((_, ele) => {
//         const buttonText = buttonTexts[buttonCount % buttonTexts.length];
//         const button = `
//                     <a href="${link}" style="color: inherit; text-decoration: none;">
//           <button
//             style="
//               background: linear-gradient(to right, #44199A, #9D77EE);
//               color: white;
//               font-size: 0.98rem;
//               font-weight: 800;
//               padding: 0.75rem 1.25rem;
//               margin: 0.75rem 0;
//               border-radius: 1.5rem;

//               height: 3.5rem;
//               transition: all 0.5s;
//               display: inline-block;
//               transform-origin: center;
//             "
//             onmouseover="this.style.background='linear-gradient(to right, #9D77EE, #9D77EE, #44199A)'; this.style.transform='scale(1.05)';"
//             onmouseout="this.style.background='linear-gradient(to right, #44199A, #9D77EE, #9D77EE)'; this.style.transform='scale(1)';"
//           >

//               ${buttonText}

//           </button>
//              </a>
//         `;

//         $(ele).before(button);
//         buttonCount++;
//       });

//       const updatedHtmlString = $.html();
//       if (referrer == `levelapp`) {
//         setModifiedHtml(htmlString as string);
//       } else {
//         setModifiedHtml(updatedHtmlString);
//       }
//       // setModifiedHtml(updatedHtmlString);
//     }
//   }, [htmlString, link, selectedLanguage]);

//   /*

//   Source Levelapp

//   If levelapp stored in localhost

//   change this

//   */

//   // setModifiedHtml(htmlString);

//   return { modifiedHtml };
// };

export function useScrollSpy(
  ids: any[],
  options: IntersectionObserverInit | undefined,
) {
  const [activeId, setActiveId] = React.useState<string | undefined>();

  const observer = React.useRef<IntersectionObserver | undefined>();
  React.useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id));
    observer.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    }, options);
    elements.forEach((el) => {
      if (el) {
        observer.current?.observe(el);
      }
    });
    return () => observer.current?.disconnect();
  }, [ids, options]);
  return activeId;
}

// hooks/useUserLocation.js

// export function useUserLocation() {
//   const [isIndia, setIsIndia] = useState(true);
//   const [isLoading, setIsLoading] = useState(true);
//   const [metaPixel, setMetaPixel] = useState(``);
//   const indiaPixel = `500207002676577`;
//   const internationalPixel = `798889752405987`;

//   useEffect(() => {
//     fetch(
//       `https://ipapi.co/json/?key=czbKS4is809Mnyqf0CLNOpPj5erMWVhbfoIrFHAfWgHMMpnpKG`,
//     )
//       .then(function (response) {
//         response.json().then((jsonData) => {
//           if (jsonData.country != `IN`) {
//             setIsIndia(false);
//             setIsLoading(false);
//             setMetaPixel(internationalPixel);
//           } else {
//             setIsIndia(true);
//             setMetaPixel(indiaPixel);
//             setIsLoading(false);
//           }
//         });
//       })
//       .catch(function (error) {
//         console.log(error);
//         setIsIndia(true);
//         setIsLoading(false);
//       });
//   }, []);

//   return { isIndia, isLoading, metaPixel };
// }

export function useUserLocation() {
  const [isIndia, setIsIndia] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [metaPixel, setMetaPixel] = useState(``);
  const indiaPixel = `500207002676577`;
  const internationalPixel = `798889752405987`;

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const localData = localStorage.getItem(`isIndia`);

        if (localData === `true`) {
          setIsIndia(true);
          setIsLoading(false);
          return;
        }

        const response = await fetch(
          `https://ipapi.co/json/?key=czbKS4is809Mnyqf0CLNOpPj5erMWVhbfoIrFHAfWgHMMpnpKG`,
        );

        const jsonData = await response.json();

        if (jsonData.country && jsonData.country !== `IN`) {
          setIsIndia(false);
          localStorage.setItem(`isIndia`, `false`);
        } else {
          setIsIndia(true);

          localStorage.setItem(`isIndia`, `true`);
        }
      } catch (error) {
        console.error(`Error fetching location:`, error);
        setIsIndia(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocation();
  }, []);

  return { isIndia, isLoading, metaPixel };
}

export function useApiLoading() {
  const [isIndia, setIsIndia] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [metaPixel, setMetaPixel] = useState(``);
  const indiaPixel = `500207002676577`;
  const internationalPixel = `798889752405987`;

  useEffect(() => {
    fetch(
      `https://ipapi.co/json/?key=czbKS4is809Mnyqf0CLNOpPj5erMWVhbfoIrFHAfWgHMMpnpKG`,
    )
      .then(function (response) {
        response.json().then((jsonData) => {
          // console.log(jsonData);

          if (jsonData.country != `IN`) {
            setIsIndia(false);
            setIsLoading(false);
            setMetaPixel(internationalPixel);
          } else {
            setIsIndia(true);
            setMetaPixel(indiaPixel);
            setIsLoading(false);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return { isIndia, isLoading, metaPixel };
}
